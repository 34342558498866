export enum Langs {
    ru = 'ru',
    en = 'en'
}

export const LangMap = {
    en: {
        'header.title.logo': 'Wheel of Fortune Spinner online',
        'header.title.info': 'Help',
        'header.title.setting': 'Settings',
        'header.title.none': '',
        'header.copy': 'Copy link',
        'header.paste': 'Paste text from clipboard',
        'menu.checkbox-label': 'Elimination mode',
        'menu.players': 'Players',
        'menu.clear': 'Clear',
        'menu.remove': 'Remove',
        'menu.back': 'Raise',
        'menu.backAll': 'Raise everything',
        'menu.defeat-players': 'Knocked out',
        'menu.legend.placeholder': 'Enter legend',
        'main.copy-success': 'Link copied',
        'main.copy-error': 'Failed to copy link',
        'main.paste-error': 'Failed to paste from clipboard',
        'editor.placeholder': 'Enter player',
        'clipboard.paste-end-msg': 'Insert items?',
        'clipboard.paste-replace-msg': 'Replace items?',
        'clipboard.dialog-yes-btn': 'Yes',
        'clipboard.dialog-no-btn': 'No',
        'panel.close': 'Close',
        'spinner-default-1': 'Yes',
        'spinner-default-2': 'No',
        'settings.rotation-time': 'Rotation time',
        'settings.rotation-s': 's',
        'settings.reset-all': 'Reset',
        'settings.players-index-visible': 'Show line index',
        'settings.new-editor-switcher': 'Multiline editor',
        "settings.legend": 'Legend',
        "settings.snow": 'Snow',
        'settings.monochrome-wheel': 'Monochrome wheel'
    },
    ru: {
        'header.title.logo': 'Колесо Фортуны онлайн',
        'header.title.info': 'Справка',
        'header.title.setting': 'Настройки',
        'header.title.none': '',
        'header.copy': 'Скопировать ссылку',
        'header.paste': 'Вставить текст из буфера',
        'menu.checkbox-label': 'На выбывание',
        'menu.players': 'Игроки',
        'menu.clear': 'Очистить',
        'menu.remove': 'Удалить',
        'menu.back': 'Вернуть',
        'menu.backAll': 'Вернуть все',
        'menu.defeat-players': 'Выбившие',
        'menu.legend.placeholder': 'Введите легенду',
        'main.copy-success': 'Ссылка скопирована',
        'main.copy-error': 'Не удалось скопировать ссылку',
        'main.paste-error': 'Не удалось вставить из буфера',
        'editor.placeholder': 'Введите игрока',
        'clipboard.paste-end-msg': 'Вставить элементы?',
        'clipboard.paste-replace-msg': 'Заменить элементы?',
        'clipboard.dialog-yes-btn': 'Да',
        'clipboard.dialog-no-btn': 'Нет',
        'panel.close': 'Закрыть',
        'spinner-default-1': 'Да',
        'spinner-default-2': 'Нет',
        'settings.rotation-time': 'Время вращения',
        'settings.rotation-s': 'с',
        'settings.reset-all': 'Сбросить',
        'settings.players-index-visible': 'Показать индекс строки',
        'settings.new-editor-switcher': 'Многострочный редактор',
        "settings.legend": 'Легенда',
        "settings.snow": 'Снег',
        'settings.monochrome-wheel': 'Монохромное колесо'
    }
};
